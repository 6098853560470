import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import clsx from 'clsx'
import { Button } from '@mui/material'
import formStyles from '@assets/css/forms.module.css'
import { useLanguage } from '@hooks/useLanguage'
import { useSnackbarActions } from '@hooks/useSnackbarActions'
import { useMutationRequestCallback } from '@hooks/ReactQuery/forms'
import { FormCheckbox, FormTextField, useZodForm } from '@controls/Form'
import { ConsentLabel } from '@controls/ConsentLabel'
import { FlexContainer } from '@layout/FlexContainer'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import { ConsentPersonalDataSchema } from '@forms/types'
import { FormValuesSchema } from '@forms/ContactForm'
import styles from './DelieveryForm.module.css'
import { sendSimpleFacebookEvent, sendTrackingEvent } from '@api/tracking'

export const DeliveryForm: React.FC = () => {
  const { t } = useTranslation()
  const { enqueueSuccessMsg } = useSnackbarActions()
  const { language } = useLanguage()

  const createDeliveryRequest = useMutationRequestCallback({
    onSuccess: () => {
      sendTrackingEvent(['gtm', 'gtag', 'fb'], 'generate_lead', {
        lead_source: 'Delivery Form',
        content_name: 'Delivery Form',
      })
      sendSimpleFacebookEvent('lead')
      enqueueSuccessMsg(t('pages.delivery.form.weHaveReceivedYourApplication'))
      form.reset()
    }
  })

  const DeliveryRequestFormSchema = FormValuesSchema.extend({
    checked: ConsentPersonalDataSchema
  })
  type DeliveryRequestForm = z.infer<typeof DeliveryRequestFormSchema>

  const form = useZodForm({ schema: DeliveryRequestFormSchema })

  const handleSubmit = (data: DeliveryRequestForm): void => {
    createDeliveryRequest.mutate({ topic: 'other', language, ...data })
  }

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <FlexContainer vertical bordered responsiveGap className={styles.form}>
        <h4 className={clsx(formStyles.formSubtitle, styles.subtitle)}>{t('pages.delivery.form.title')}</h4>
        <FormTextField
          control={form.control}
          name="name"
          type="text"
          variant="standard"
          placeholder={t('global.forms.customer.fields.name')}
        />
        <FormTextField
          control={form.control}
          name="phoneNumber"
          type="text"
          variant="standard"
          placeholder={t('global.forms.customer.fields.phone')}
        />
        <FormTextField
          control={form.control}
          name="email"
          type="text"
          variant="standard"
          placeholder={t('global.forms.customer.fields.email')}
        />
        <FormCheckbox control={form.control} name="checked" label={<ConsentLabel />} />
        <ActionButtonsContainer justifyContentCenter>
          <Button variant="contained" type="submit">
            {t('pages.contactStrip.button')}
          </Button>
        </ActionButtonsContainer>
      </FlexContainer>
    </form>
  )
}
